'use client';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';

import React, { useEffect } from 'react';
import Link from 'next/link';
import { Avatar, AvatarFallback, AvatarImage } from '../components/ui/avatar';

export default function SimpleNotFound({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  const imageUrl = '/logo-hotpresso.svg';
  return (
    <div className="w-full min-h-screen bg-foreground">
      <div className="mx-auto min-h-full flex justify-center items-center h-screen">
        <div className="text-background ">
          <Card className="w-[350px]">
            <CardHeader>
              <div className="flex justify-center items-center mb-2 ">
                <Avatar className="h-16 w-16">
                  <AvatarImage src={imageUrl} alt={'hotpresso logo'} />
                  <AvatarFallback>{'HP'}</AvatarFallback>
                </Avatar>
                {/* <Avatar className="h-16 w-16">
                    <AvatarImage src={imageUrl} alt={imageAlt} />
                    <AvatarFallback>{imageAlt}</AvatarFallback>
                  </Avatar> */}
              </div>
              <CardTitle>Boom happened</CardTitle>
              <CardDescription>
                {'Looks like we encountered an error'}
              </CardDescription>
            </CardHeader>
            <CardContent></CardContent>
            <CardFooter className="flex justify-center">
              {/* <Button variant="outline">Cancel</Button> */}
              <Button
                variant={'default'}
                onClick={
                  // Attempt to recover by trying to re-render the segment
                  () => reset()
                }
              >
                <Link href="/login">Try Again</Link>
              </Button>
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );
}
